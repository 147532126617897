import React, { useEffect, useState } from 'react'
import { TagPicker, Button, Form, Modal, List, Input, Table, Header, Tag, Alert, Container } from 'rsuite'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
const axios = require('axios')

const { API_CONFIG } = require('../RequestManager')

export default function SignIn({ history }) {
  const [error, setError] = useState('')
  const [password, setPassword] = useState()

  const loginPush = () => {
    axios
      .post(`${API_CONFIG.API_URL}/auth`, {
        password: password
      })
      .then((result) => {
        cookies.set('token', result.data.token)
        history.push('/dashboard')
      })
      .catch((error) => {
        Alert.error('Failed to login')
      })
      .then((result) => {
        setPassword('')
      })
  }

  return (
    <>
      <div className='loginContainer fillVertical'>
        <div className='loginBox'>
          <h3>Sign in</h3>
          <Input type='password' placeholder='Enter password' value={password} onChange={(e) => setPassword(e)} />
          <Button appearance='primary' block onClick={loginPush}>
            Log In
          </Button>
        </div>
      </div>
    </>
  )
}
