import React from 'react'
import { useState } from 'react'
import { Sidenav, Nav, Icon } from 'rsuite'

function Sidebar() {
  const [activeKey, setActiveKey] = useState('1')

  return (
    <div style={{ width: 250 }} className='sidebar'>
      <Sidenav activeKey={activeKey} className='sidebarContent'>
        <Sidenav.Header>
          <h3>Do Good</h3>
        </Sidenav.Header>
        <Sidenav.Body>
          <Nav>
            <Nav.Item eventKey='1' icon={<Icon icon='th-list' />} href='/dashboard/repo' onSelect={() => setActiveKey('1')}>
              Repository
            </Nav.Item>
            <Nav.Item eventKey='2' icon={<Icon icon='tags' />} href='/dashboard/tags' onSelect={() => setActiveKey('2')}>
              Tags
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  )
}

export default Sidebar
