let API_CONFIG = {
  API_URL: 'https://beta.api.dogood.oneenterprises.app' // Default to BETA
}

if (process.env.REACT_APP_STAGE === 'LOCAL') {
  API_CONFIG.API_URL = 'http://localhost:3000'
}
if (process.env.REACT_APP_STAGE === 'BETA') {
  API_CONFIG.API_URL = 'https://beta.api.dogood.oneenterprises.app'
}
if (process.env.REACT_APP_STAGE === 'PRODUCTION') {
  API_CONFIG.API_URL = 'https://api.dogood.oneenterprises.app'
}

module.exports = {
  API_CONFIG
}
