import React, { useEffect, useState } from 'react'
import { TagPicker, Button, Form, List, Input, Table, Header, Tag, Alert } from 'rsuite'
import Cookies from 'universal-cookie'
import Modal from '../components/RepositoryModal'

const cookies = new Cookies()

const axios = require('axios')

const { Column, HeaderCell, Cell, Pagination } = Table

const { API_CONFIG } = require('../RequestManager')

function App() {
  const [items, setItems] = useState([])
  const [tags, setTags] = useState([])
  const [error, setError] = useState('')

  const [modalAction, setModalAction] = useState(undefined)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [itemId, setItemId] = useState(undefined)
  const [itemTitle, setItemTitle] = useState('')
  const [itemURL, setItemURL] = useState('')
  const [itemTags, setItemTags] = useState([])
  const [itemImage, setItemImage] = useState('')
  const [itemImageEnabled, setItemImageEnabled] = useState(false)

  const URLCell = (props) => {
    let isValidURL = validURL(props.rowData.url)
    return (
      <Cell {...props} key={`urlcell${props.rowData.id}`} style={isValidURL ? null : { backgroundColor: '#c0392b' }}>
        <a href={props.rowData.url} style={isValidURL ? null : { color: 'white' }}>
          {props.rowData.url}
        </a>
      </Cell>
    )
  }

  const ImageCell = (props) => {
    return (
      <Cell {...props} key={`imagecell${props.rowData.id}`}>
        <img src={props.rowData.image} className={`imageCellImage ${props.rowData.imageEnabled ? '' : 'disabled'}`} />
      </Cell>
    )
  }

  const TagsCell = (props) => {
    return (
      <Cell {...props} key={`tagscell${props.rowData.id}`}>
        {props.rowData.tags.map((tag, idx) => {
          return <Tag key={`tag${props.rowData.id}${idx}`}>{tag.tag}</Tag>
        })}
      </Cell>
    )
  }

  const ActionsCell = (props) => {
    return (
      <Cell {...props} key={`actioncell${props.rowData.id}`}>
        {(rowData) => {
          return (
            <span>
              <Button appearance='ghost' color='red' size='xs' onClick={() => deleteItem(props.rowData.id)}>
                Delete
              </Button>
              &nbsp; &nbsp;
              <Button appearance='ghost' color='blue' size='xs' onClick={() => handleEditItemClick(props.rowData.id)}>
                Edit
              </Button>
            </span>
          )
        }}
      </Cell>
    )
  }

  const changeURL = async (e) => {
    if (e.length > 5) {
      axios.get(`${API_CONFIG.API_URL}/posts/metadata?url=${encodeURI(e)}`).then(function (response) {
        const metadata = response.data
        setItemTitle(metadata.ogTitle)
        setItemImage(metadata.ogImage.url)
        setItemImageEnabled(true)
      })
    }

    setItemURL(e)
  }

  const getAllItems = () => {
    axios
      .get(`${API_CONFIG.API_URL}/posts`)
      .then(function (response) {
        let items = response.data.sort((a, b) => (a.created < b.created ? 1 : -1))
        setItems(items)
      })
      .catch(function (error) {
        Alert.error('Failed to fetch posts')
      })
  }

  const addPost = async () => {
    if (itemTitle.length === 0 || itemURL.length === 0) {
      setError('Please write a title and supply a URL')
      return
    }

    if (itemTags.length === 0) {
      setError('Please assign at least one tag')
      return
    }

    if (!validURL(itemURL)) {
      setError('Please enter a valid URL')
      return
    }

    let token = await cookies.get('token')
    setIsLoading(true)
    axios
      .post(
        `${API_CONFIG.API_URL}/posts`,
        {
          title: itemTitle,
          url: itemURL,
          image: itemImage,
          imageEnabled: itemImageEnabled,
          tags: itemTags.map((item) => item.id).join(':::')
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: token,
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      .then(function (response) {
        Alert.success('Your post was added')
        getAllItems()
      })
      .catch(function (error) {
        Alert.error('Failed to add post')
      })
      .then(function () {
        setItemId()
        setItemTags([])
        setItemTitle()
        setItemImageEnabled(false)
        setItemURL()
        setShowModal(false)
        setModalAction(undefined)
        setIsLoading(false)
      })
  }

  const deleteItem = async (postid) => {
    let token = await cookies.get('token')
    axios
      .delete(`${API_CONFIG.API_URL}/posts/${postid}`, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: token,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(function (response) {
        console.log(response)
        getAllItems()
        Alert.success(`Deleted post`)
      })
      .catch(function (error) {
        Alert.error('Failed to delete post')
        console.log(error)
      })
  }

  const editItem = async (postId) => {
    let token = await cookies.get('token')
    setIsLoading(true)
    axios
      .put(
        `${API_CONFIG.API_URL}/posts/${postId}`,
        {
          title: itemTitle,
          url: itemURL,
          image: itemImage,
          imageEnabled: itemImageEnabled,
          tags: itemTags.map((item) => item.id).join(':::')
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: token,
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      .then(function (response) {
        Alert.success(`Updated post`)
      })
      .catch(function (error) {
        Alert.error('Failed to update post')
      })
      .finally(() => {
        setItemId()
        setItemTags([])
        setItemTitle()
        setItemImage()
        setItemImageEnabled(false)
        setItemURL()
        getAllItems()
        setShowModal(false)
        setModalAction(undefined)
        setIsLoading(false)
      })
  }

  const handleEditItemClick = async (postId) => {
    setModalAction('edit')
    const post = items.find((item) => item.id === postId)
    setItemURL(post.url)
    setItemTags(post.tags)
    setItemTitle(post.title)
    setItemImage(post.image)
    setItemImageEnabled(post.imageEnabled)
    setItemId(postId)
    setShowModal(true)
  }

  const getTags = () => {
    axios
      .get(`${API_CONFIG.API_URL}/tags`)
      .then(function (response) {
        setTags(response.data)
      })
      .catch(function (error) {
        Alert.error('Failed to fetch tags')
      })
  }

  useEffect(() => {
    getAllItems()
    getTags()
  }, [])

  return (
    <>
      <div className='repo-container'>
        <div className='header'>
          <h2>Repository</h2>
          <Button
            appearance='primary'
            onClick={() => {
              setModalAction('add')
              setShowModal(true)
            }}>
            Add Item
          </Button>
        </div>
        <div className='ListContainer'>
          <Table data={items} height={700}>
            <Column width={350}>
              <HeaderCell>Post Title</HeaderCell>
              <Cell dataKey='title'></Cell>
            </Column>
            <Column width={300}>
              <HeaderCell>URL</HeaderCell>
              <URLCell></URLCell>
            </Column>
            <Column width={100}>
              <HeaderCell>Image</HeaderCell>
              <ImageCell></ImageCell>
            </Column>
            <Column width={200}>
              <HeaderCell>Tags</HeaderCell>
              <TagsCell></TagsCell>
            </Column>
            <Column width={200}>
              <HeaderCell>Action</HeaderCell>
              <ActionsCell></ActionsCell>
            </Column>
          </Table>
        </div>
      </div>

      <Modal
        showModal={showModal}
        onHide={() => {
          setShowModal(false)
          setItemId()
          setItemImage()
          setItemImageEnabled(false)
          setItemTags([])
          setItemTitle()
          setItemURL()
        }}
        onSubmit={modalAction === 'edit' ? () => editItem(itemId) : () => addPost()}
        title={modalAction === 'edit' ? 'Edit Post' : 'Add New Post'}
        submitLabel={modalAction === 'edit' ? 'Update Post' : 'Create Post'}
        isLoading={isLoading}
        tags={tags}
        error={error}
        itemTitle={itemTitle}
        itemURL={itemURL}
        itemImage={itemImage}
        itemImageEnabled={itemImageEnabled}
        itemTags={itemTags}
        changeURL={changeURL}
        setItemTitle={setItemTitle}
        setItemTags={setItemTags}
        setItemImage={setItemImage}
        setItemImageEnabled={setItemImageEnabled}
      />
    </>
  )
}

export default App

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}
