import React, { useEffect, useState } from 'react'
import 'rsuite/dist/styles/rsuite-default.css'
import { TagPicker, Button, Form, Modal } from 'rsuite'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import Main from './pages/Main'
import Login from './pages/Login'

const axios = require('axios')

function App() {
  useEffect(() => {
    console.log('process.env.REACT_APP_STAGE', process.env.REACT_APP_STAGE)
  }, [])

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/dashboard/*' component={Main} />
          <Redirect to='/dashboard/repo' path='/dashboard' />
          <Redirect to='/login' path='/' />
        </Switch>
      </BrowserRouter>
    </>
  )
}

export default App
