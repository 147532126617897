import React, { useEffect, useState } from 'react'
import 'rsuite/dist/styles/rsuite-default.css'
import { TagPicker, Button, Form, Modal, Input, Table, Column, HeaderCell, List } from 'rsuite'
import Cookies from 'universal-cookie'
const cookies = new Cookies()
const axios = require('axios')

const { API_CONFIG } = require('../RequestManager')

function App({ history }) {
  const [tags, setTags] = useState([])
  const [tagInput, setTagInput] = useState()

  const addTag = async () => {
    if (tagInput.length === 0) {
      return
    }

    let token = await cookies.get('token')

    axios
      .post(
        `${API_CONFIG.API_URL}/tags`,
        {
          tag: tagInput
        },
        {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Authorization: token,
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      .then(function (response) {
        // handle success
        console.log(response)
        setTagInput('')
        getTags()
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
      .then(function () {
        // always executed
      })
  }

  const getTags = () => {
    axios
      .get(`${API_CONFIG.API_URL}/tags`)
      .then(function (response) {
        // handle success
        console.log(response)
        setTags(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
      .then(function () {
        // always executed
      })
  }

  useEffect(() => {
    getTags()
  }, [])

  return (
    <>
      <div className='repo-container'>
        <div className='header'>
          <div>
            <h2>Tags</h2>
            <p>This is a list of all possible tags that can be assigned to repository items</p>
          </div>

          <div className='flex-row tagInputGroup'>
            <Input type='text' value={tagInput} onChange={(e) => setTagInput(e)} />
            <Button appearance='primary' onClick={addTag}>
              Add Tag
            </Button>
          </div>
        </div>
        <div className='ListContainer'>
          <List>
            {tags.map((item, index) => (
              <List.Item key={index} index={index}>
                {item.tag}
              </List.Item>
            ))}
          </List>
        </div>
      </div>
    </>
  )
}

export default App
