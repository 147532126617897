import React, { useEffect, useState } from 'react'
import { TagPicker, Button, Form, Modal, List, Input, Table, Header, Tag, Alert, Toggle } from 'rsuite'

function RepositoryModal(props) {
  return (
    <Modal show={props.showModal} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='modal-form'>
          <Input type='text' value={props.itemURL} placeholder='Post URL' onChange={(e) => props.changeURL(e)} key='urlinputkey' />
          <Input type='text' value={props.itemTitle} placeholder='Post Title' onChange={(e) => props.setItemTitle(e)} key='titleinputkey' />
          <div className='modalImageBox'>
            <img src={props.itemImage} className='modalImage' key='imageinputkey' />
            <Toggle size='lg' checkedChildren='Image' unCheckedChildren='No Image' checked={props.itemImageEnabled} onChange={(e) => props.setItemImageEnabled(e)} />
          </div>

          <TagPicker
            data={props.tags}
            labelKey='tag'
            valueKey='id'
            block
            value={props.itemTags.map((tag) => tag.id)}
            onChange={(tagIDs) => {
              const newTags = tagIDs.map((id) => props.tags.find((tag) => tag.id === id))
              props.setItemTags(newTags)
            }}
            key='tagpickerkey'></TagPicker>
        </div>
        {props.error.length > 0 && <div className='redText'>{props.error}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button appearance='secondary' onClick={props.onHide}>
          Close
        </Button>
        <Button appearance='primary' onClick={props.onSubmit} loading={props.isLoading}>
          {props.submitLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default RepositoryModal
