import React, { useEffect, useState } from 'react'
import 'rsuite/dist/styles/rsuite-default.css'
import { TagPicker, Button, Form, Modal } from 'rsuite'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import Sidebar from '../components/Sidebar'
import Repository from './Repository'
import Tags from './Tags'

import Cookies from 'universal-cookie'
const cookies = new Cookies()

const axios = require('axios')

function App({ history }) {
  useEffect(() => {
    const authenticate = async () => {
      let tok = await cookies.get('token')
      if (!tok || tok.length < 30) {
        history.replace('/login')
      }
    }
    authenticate()
  }, [])

  return (
    <>
      <Sidebar />
      <div className='mainContainer'>
        <Switch>
          <Route path='/dashboard/repo' component={Repository} />
          <Route path='/dashboard/tags' component={Tags} />
        </Switch>
      </div>
    </>
  )
}

export default App
